.filter-button {
    font-size: 14px;
    border: #ececec 1px solid;
    padding: 4px;
    border-radius: 4px;
    min-width: 38px;
    cursor: pointer;
    display: inline-block;
}

.filter-button.inactive {
    filter: grayscale(100%);
    background-color: #ececec;
}

.filter-button:hover {
    border: black 1px solid;
    background: #ececec;
}